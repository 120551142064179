<template>
  <div class="container">
    <nav class="d-flex align-items-center mb-lg-4 mb-3" aria-label="breadcrumb">
      <router-link class="text-brown text-decoration-none mr-3" :to="'/product' + ($route.hash ? $route.hash : '')">
        <svg
          class="mr-3"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_15_368)">
            <path
              d="M31.866 29.046C31.916 28.71 32.975 20.636 28.227 15.08C25.31 11.666 20.809 9.79703 14.997 9.61203L14.966 3.99903C14.966 3.61303 14.743 3.26203 14.395 3.09603C14.046 2.93303 13.633 2.98003 13.334 3.22403L0.366998 13.866C0.134999 14.056 -1.52259e-06 14.34 -0.00100152 14.64C-0.00200152 14.94 0.131998 15.224 0.363998 15.414L13.332 26.157C13.63 26.404 14.047 26.455 14.393 26.289C14.744 26.124 14.966 25.771 14.966 25.385L14.997 19.646C29.006 19.636 30.002 28.612 30.036 28.983C30.079 29.487 30.398 29.88 30.904 29.896C30.916 29.897 30.927 29.897 30.938 29.897C31.433 29.897 31.792 29.536 31.866 29.046ZM13.261 17.922C13.073 18.11 12.967 18.364 12.967 18.63V23.268L2.578 14.646L12.967 6.10403V10.597C12.967 11.15 13.414 11.597 13.967 11.597C19.657 11.597 24.004 13.245 26.702 16.373C28.731 18.727 29.664 21.608 29.983 23.999C27.799 21.015 23.975 17.636 13.968 17.63C13.968 17.63 13.967 17.63 13.966 17.63C13.702 17.63 13.448 17.735 13.261 17.922Z"
              fill="#6B5045"
            />
          </g>
          <defs>
            <clipPath id="clip0_15_368">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
        返回
      </router-link>
      <ol class="breadcrumb mb-0 bg-transparent p-0">
        <li class="breadcrumb-item">
          <router-link to="/">主頁</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/product'+ ($route.hash ? $route.hash : '')">產品推廣</router-link>
        </li>
        <li class="breadcrumb-item">存貨</li>
      </ol>
    </nav>

    <b-alert show variant="danger" v-if="veeErrors.all().length">
      <ul class="list-unstyled mb-0">
        <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">
          {{ error }}
        </li>
      </ul>
    </b-alert>
    <div class="card rounded-0 p-lg-4 border-0 p-3">
      <h5 class="text-brown py-2">存貨</h5>
      <b-row class="mb-3">
        <b-col sm="6" lg="2">
          <img
            height="160"
            class="img-fluid rounded-sm shadow-sm"
            v-if="this.details.imageUrl"
            v-bind:src="this.details.imageUrl[0]"
          />
        </b-col>
        <b-col sm="6" lg="4" class="align-self-center">
          <p><span>產品名稱 : </span>{{ this.details.nameZhHk }}</p>
          <p><span>產品系列  : </span> {{ this.details.posId }}</p>
          <p><span>價格 : </span> HKD {{ this.details.price }}</p>
          <p><span>折扣 : {{ this.details.discountPercentage }} %</span></p>
          <p v-if="this.details.discountPercentage"><span>折後價 : HKD {{ (this.details.price / 100 * ( 100 - this.details.discountPercentage )).toFixed(2)}}</span></p>
        </b-col>
      </b-row>
      <b-form @submit.stop.prevent="onSubmitUpdateProduct">
        <b-row v-if="!options.length" class="align-items-center" >
          <b-col sm="2">
            <label for="">
              <h6>產品可用</h6>
            </label>
          </b-col>
          <b-col sm="6">
            <b-form-input
              :id="`inventory-product`"
              class="rounded-0 mb-2"
              :name="`inventory-product`"
              v-model="proInventory"
              v-validate="{ required: true }"
              :state="validateState('inventory-product')"
              :aria-describedby="`input-inventory-product-live-feedback`"
              :data-vv-as="`inventory-product`"
              @keydown="numberPositiveIntegerOnly"
            ></b-form-input>
          </b-col>
        </b-row>
        <template v-else>
        <b-row>
          <b-col sm="2">
            <h6>選項</h6>
          </b-col>
          <b-col sm="6"><h6>可用的</h6></b-col>
        </b-row>
        <div v-for="(item, idx) in options" :key="idx" class="my-2 w-full">
          <b-row>
            <b-col sm="2">
              <label :for="`optionType-${idx}`">
                <h6>{{ item.optionValue }}</h6>
              </label>
            </b-col>
            <b-col sm="6">
              <b-form-input
                v-if="!item.child?.length"
               @keydown="numberPositiveIntegerOnly"
                :id="`inventory-${idx}`"
                class="rounded-0 mb-2"
                :name="`inventory-${idx}`"
                v-model="item.inventory"
                v-validate="{ required: true }"
                :state="validateState(`inventory-${idx}`)"
                :aria-describedby="`input-inventory-${idx}-live-feedback`"
                :data-vv-as="`inventory-${idx}`"
              ></b-form-input>

              <div v-for="(item, idx) in item.child" :key="idx" class="w-100 d-flex align-items-center">
                <!-- <IconArrowReturnRight style="width: 20px; height: 20px;" class="mb-3 mr-2"></IconArrowReturnRight> -->
                <div class="w-100 align-items-center" style="column-gap: 10px; display: grid; grid-template-columns: auto auto;">
                  <label :for="`optionType-${idx}`" class="w-full">
                    <h6>{{ item.value }}</h6>
                  </label>

                  <b-form-input
                    v-if="!item.child"
                    @keydown="numberPositiveIntegerOnly"
                    :id="`inventory-${idx}`"
                    class="rounded-0 mb-2"
                    :name="`inventory-${idx}`"
                    v-model="item.inventory"
                    v-validate="{ required: true }"
                    :state="validateState(`inventory-${idx}`)"
                    :aria-describedby="`input-inventory-${idx}-live-feedback`"
                    :data-vv-as="`inventory-${idx}`"
                  ></b-form-input>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        </template>
        <b-button
          :disabled="!hasVeeErrors"
          type="submit"
          variant="primary"
          class="rounded-10px px-lg-5 float-right px-3"
          >儲存</b-button
        >
      </b-form>
    </div>
  </div>
</template>

<script>
import { updateProductInventory, getProduct, updateProductInventoryById, getInventoryByProId } from '@/services/product.service'
// import IconArrowReturnRight from '../../components/icon  s/IconArrowReturnRight.vue'

export default {
  name: 'InventoryView',
  components: {
    // IconArrowReturnRight
  },
  data: () => ({
    productID: null,
    details: {},
    currentIndex: null,
    options: [
      {
        productOptionId: 0,
        optionType: '',
        optionValue: '',
        productId: 0,
        inventory: null
      }
    ],
    proInventory: null,
    proInventoryDb: null
  }),

  mounted () {
    this.productID = this.$route.params.productId
    this.getProductAllOption()
  },

  computed: {
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },

  methods: {
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    async getProductAllOption () {
      const response = await getProduct(this.$route.params.productId)
      this.details = response.data.data
      this.options = this.details.productOptionViewDTOS.map(item => (
        {
          ...item,
          child: item.child
        }
      ))
      if (!this.options.length) {
        const proResponse = await getInventoryByProId(this.$route.params.productId)
        this.proInventory = proResponse
        this.proInventoryDb = proResponse
      }
    },
    async onSubmitUpdateProduct () {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          return
        }
        if (this.proInventory !== this.proInventoryDb && !this.options.length) {
          updateProductInventoryById(this.$route.params.productId, this.proInventory)
        }
        const requests = this.options.map((item) => {
          if (item.child?.length) {
            item.child.map((item) => { updateProductInventory(item.id, item.inventory) })
          } else { updateProductInventory(item.productOptionId, item.inventory) }
        })
        await Promise.all(requests).then(
          (res) => {
            if (res) {
              this.makeToast(
                'success',
                'Inventory',
                'Inventory has been updated'
              )
              setTimeout(() => {
                this.$router.push('/product' + (this.$route.hash ? this.$route.hash : ''))
              }, 150)
            }
          },
          (err) => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          }
        )
      })
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    numberPositiveIntegerOnly (e) {
      // List of allowed keys: number keys, arrow keys, backspace, and delete
      const allowedKeys = [
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
        'Backspace', 'Delete'
      ]

      // Prevent default action if the key pressed is not in the allowedKeys list
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault()
        return false
      }

      // Allow control keys (navigation and deletion) without additional checks
      if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete'].includes(e.key)) {
        // Control keys do not modify the numeric value
      }
    }

  }
}
</script>
